import { Box, Grid } from '@mui/material';
import DOMPurify from 'dompurify';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Arrow from '../../../assets/img/complete-arrow@2x.png';
import { plexoProducts } from '../../../data/products';
import { Product } from '../../../types/products.types';
import ScrolledText from '../ScrolledText/ScrolledText';

function Products() {
  const { t } = useTranslation();

  return (
    <Box id="products">
      <span id="products-anchor" />
      <Box className="section">
        <Grid container spacing={2} sx={{ justifyContent: 'center' }}>
          <Grid item xs={12}>
            <h2 dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(t('products-title')) }}></h2>
          </Grid>
          {plexoProducts.map((product: Product) => (
            <Grid item xs={12} md={4} className="cards-container" key={product.title}>
              <div className="card">
                <img src={product.img} alt={product.title} />
                <div className="text-container">
                  <h3>{t(product.title)}</h3>
                  <p
                    dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(t(product.text)) }}
                    className="text"
                  />
                  <div className="button">
                    <Link to={product.url}>
                      {t('products-button')}
                      <img src={Arrow} alt="Arrow" />
                    </Link>
                  </div>
                </div>
              </div>
            </Grid>
          ))}
        </Grid>
      </Box>
      <ScrolledText />
    </Box>
  );
}

export default Products;
