import { Stack, SxProps, Theme, Tooltip } from '@mui/material';
import { ResponsiveStyleValue, SystemStyleObject } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Arrow from '../../assets/img/complete-arrow@2x.png';
import { Logo } from '../../types/logos.types';

interface CustomLogoGridProps {
  logos: Logo[];
  itemsPerRow: number[];
  colSpacing?: ResponsiveStyleValue<number | string>;
  rowSpacing?: ResponsiveStyleValue<number | string>;
  sx?: LogoGridSx;
  linkTo?: { pathname: string; hash: string };
  customGridStyle?: SxProps;
}

interface LogoGridSx {
  root?: SxProps;
  item?: SxProps;
}

const gridStyle: SystemStyleObject<Theme> = {
  height: '100%',
  paddingTop: { md: '1.5rem' },
  display: { xs: 'none', md: 'flex' },
  alignItems: 'start',
  '& img': { width: '76px', height: '76px', borderRadius: '50%' },
  '& .button': {
    height: '100%',
  },
};

function CustomLogoGrid({
  logos,
  itemsPerRow,
  colSpacing = 3,
  rowSpacing,
  linkTo,
  customGridStyle,
}: CustomLogoGridProps): JSX.Element {
  const { t } = useTranslation();

  const renderRows = () => {
    let logoIndex = 0;
    return itemsPerRow.map((itemsInRow, rowIndex) => {
      const rowItems = logos.slice(logoIndex, logoIndex + itemsInRow).map((logo) => (
        <Tooltip title={t(logo.title)}>
          <img key={`${logo.title}`} src={logo.img} alt={t(logo.title)} />
        </Tooltip>
      ));
      logoIndex += itemsInRow;

      return (
        <Stack key={rowIndex} flex={1} gap={colSpacing} direction="row" mb={rowSpacing}>
          {rowItems}
          {linkTo && rowIndex === itemsPerRow.length - 1 && (
            <Link to={linkTo} className="button">
              {t('payment-methods-button')}
              <img src={Arrow} alt="Go to gateway solutions" />
            </Link>
          )}
        </Stack>
      );
    });
  };

  return (
    <Stack direction="column" sx={{ ...gridStyle, ...customGridStyle }}>
      {renderRows()}
    </Stack>
  );
}

export default CustomLogoGrid;
