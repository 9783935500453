import PlexoLinksManualPdf from '../assets/docs/px-links-manual.pdf';
import PlexoSubscriptionsManualPdf from '../assets/docs/px-subscriptions-manual.pdf';
import DonationsIcon from '../assets/img/icon-donac@2x.png';
import CommonExpensesIcon from '../assets/img/icon-ggcc@2x.png';
import PlexoLinksIcon from '../assets/img/icon-links@2x.png';
import CommerceIcon from '../assets/img/icon-pasarela@2x.png';
import SubscriptionsIcon from '../assets/img/icon-suscrip@2x.png';
import Donations from '../assets/img/img-card-donations@2x.png';
import CommonExpenses from '../assets/img/img-card-ggcc@2x.png';
import PlexoLinks from '../assets/img/img-card-links@2x.png';
import Commerce from '../assets/img/img-card-pasarela@2x.png';
import Subscriptions from '../assets/img/img-card-suscrip@2x.png';
import {
  commerceFaqs,
  commonExpensesFaqs,
  donationsFaqs,
  plexoLinksFaqs,
  subscriptionsFaqs,
} from '../data/faqs';
import { Product } from '../types/products.types';

const eCommerce: string = 'products.e-commerce';
const plexoLinks: string = 'products.plexo-links';
const plexoSubscriptions: string = 'products.subscriptions';

export const plexoProducts: Product[] = [
  {
    img: Commerce,
    icon: CommerceIcon,
    title: `${eCommerce}.title`,
    shortTitle: `${eCommerce}.shortTitle`,
    text: `${eCommerce}.text`,
    navText: `${eCommerce}.nav-text`,
    url: '/productos/pasarela',
    faqs: commerceFaqs,
  },
  {
    img: PlexoLinks,
    icon: PlexoLinksIcon,
    title: `${plexoLinks}.title`,
    shortTitle: `${plexoLinks}.shortTitle`,
    moduleTitle: `${plexoLinks}.moduleTitle`,
    text: `${plexoLinks}.text`,
    navText: `${plexoLinks}.nav-text`,
    url: '/productos/plexo-links',
    attachment: {
      path: PlexoLinksManualPdf,
      filename: `${plexoLinks}.manual-filename`,
    },
    faqs: plexoLinksFaqs,
  },
  {
    img: Subscriptions,
    icon: SubscriptionsIcon,
    title: `${plexoSubscriptions}.title`,
    shortTitle: `${plexoSubscriptions}.shortTitle`,
    moduleTitle: `${plexoSubscriptions}.moduleTitle`,
    text: `${plexoSubscriptions}.text`,
    navText: `${plexoSubscriptions}.nav-text`,
    url: '/productos/suscripciones',
    attachment: {
      path: PlexoSubscriptionsManualPdf,
      filename: `${plexoSubscriptions}.manual-filename`,
    },
    faqs: subscriptionsFaqs,
  },
  {
    img: Donations,
    icon: DonationsIcon,
    title: 'products.donations.title',
    shortTitle: 'products.donations.shortTitle',
    text: 'products.donations.text',
    navText: 'products.donations.nav-text',
    url: '/productos/donaciones',
    faqs: donationsFaqs,
  },
  {
    img: CommonExpenses,
    icon: CommonExpensesIcon,
    title: 'products.common-expenses.title',
    shortTitle: 'products.common-expenses.shortTitle',
    text: 'products.common-expenses.text',
    navText: 'products.common-expenses.nav-text',
    url: '/productos/proptech',
    faqs: commonExpensesFaqs,
  },
];
